<style lang="less" scoped>
  @import "../../../assets/css/variables";

  .customer-content {
    margin: 0 auto;
    width: 92%;
    .el-form-item {
      margin-bottom: 14px !important;
    }
  }
  .order-image-wrapper {
    position: relative;
    height: 120px;
    width: 120px;
    border: 1px solid #bfcbd9;
    border-radius: 4px;
    font-size: 100px;
    line-height: 120px;
    text-align: center;
    color: #ccc;
    cursor: pointer;
    span {
      font-size: 20px;
      line-height: 120px;
      display: block;
    }
    .image-content {
      position: relative;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .image-close {
      position: absolute;
      right: 0;
      top: 0;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      background-color: #1c2b36;
      color: #fff;
      font-size: @font-size-base;
    }
  }
  .Details-of-the-module {
    width: 100%;
    height: 100%;
    background: #fff;
  }
</style>

<template>
  <div class="content">
    <div class="customer-content">
      <el-form
        ref="pubNumberForm"
        :rules="rules"
        :model="companyInfo"
        label-width="90px"
        label-position="right"
      >
        <div class="page-wrapper">
          <el-breadcrumb separator="/">
            <!-- <el-breadcrumb-item :to="{ path: '/customer/company' }"
                >公司介绍列表</el-breadcrumb-item
              > -->
            <el-breadcrumb-item>公司介绍</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <div class="Details-of-the-module">
          <el-row :gutter="5" style="margin-bottom: 5px">
            <el-col :span="12">
              <el-form-item label="公司名称:" prop="name">
                <el-input
                  v-model="companyInfo.name"
                  placeholder="请输入公司名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item v-if="isEdit" label="品牌商:" prop="name">
                <el-input v-model="comName" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="品牌商:" v-else prop="customer">
                <el-select
                  v-model="companyInfo.customerName"
                  filterable
                  clearable
                  :disabled="isBrand"
                  placeholder="选择品牌商"
                >
                  <el-option
                    v-for="item in brandList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="图 片:" prop="imageUrl">
            <form
              id="headImgfileForm"
              class="none"
              method="post"
              enctype="multipart/form-data"
            >
              <input
                type="file"
                id="orderImageFile"
                @change="selectedImageFile($event, 'img')"
                name="upfile"
                accept="image/*;capture=camera"
              />
            </form>
            <div class="image-content m-t-md clearfix" style="margin-top: -5px">
              <div
                @click="triggerSelectFile"
                class="order-image-wrapper pull-left"
              >
                <span>426 x 306</span>
              </div>
              <div
                @click="delImage(index)"
                :class="[
                  'order-image-wrapper',
                  'pull-left',
                  'm-b-md',
                  { 'image-m-l': (index + 1) % 3 !== 0 },
                ]"
                v-for="(image, index) in imageList"
              >
                <div
                  class="image-content"
                  :style="{
                    'background-image':
                      'url(' + API_ROOT + image.serverImage + ')',
                  }"
                  v-loading.body="loading"
                ></div>
                <div class="image-close" style="background-color: transparent">
                  <i class="fa fa-times-circle" style="color: #f00"></i>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item type label="内容:" prop="remarks" style="height: 380px">
            <!-- <el-input type="textarea" :rows="10" :maxlength="200" v-model="infoBase.content"></el-input> -->
            <quill-editor
              v-model="companyInfo.remarks"
              style="height: 265px; z-index: 99999"
              ref="myQuillEditor"
            ></quill-editor>
          </el-form-item>
        </div>

        <!-- <el-form-item class="text-right m-t-md">
          <el-button type="primary" size="large" @click="onSubmit"
            >保存</el-button
          >
        </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>
<script>
  import {
    createCompany,
    queryBranderExist,
    getCustomerById,
    updateCompanyInfo,
    searchCompanysByKey,
  } from "../../../services/customer";
  import { mapGetters, mapActions } from "vuex";
  import { API_ROOT } from "../../../config";
  import { getFileUrl } from "../../../utils/tools/tools";
  import { Notification } from "element-ui";
  import { uploadImageFile } from "../../../services/common";
  import { valiInput, valiSelect } from "../../../utils/validator/element";
  import { quillEditor } from "vue-quill-editor";

  export default {
    data() {
      return {
        API_ROOT,
        comName: "",
        imageList: [],
        imgUrlList: [],
        qrcodeList: [], //二维码图片列表
        disabled: false,
        isBrand: false,
        pager: {
          size: 20,
          page: 1,
        },
        loading: false, //加载框
        companyInfo: {},
        brandList: [],
        rules: {
          name: [{ validator: valiInput, trigger: "blur" }],
          customer: [{ validator: valiSelect, trigger: "change" }],
          // isProxy: [
          //   {validator: valiSelect, trigger: 'change'}
          // ],
          // remarks: [
          //   {require: false, min: 2, max: 30, message: '长度在2到30个字符', trigger: 'blur'}
          // ]
        },
      };
    },
    components: {
      quillEditor,
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill;
      },
      ...mapGetters({ sorts: "sorts" }),
      ...mapGetters({ user: "userInfo" }),
    },
    props: {
      customerDetailVisible: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
      isEdit: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
      isRootLevel: {
        type: Boolean,
        default: function() {
          return true;
        },
      },
      propcompanyInfo: {
        type: Object,
        default: function() {
          return {
            id: "",
            name: "",
            linkUrl: "",
            customer: "",
            contact: "",
            mobile: "",
            appId: "",
          };
        },
      },
    },
    methods: {
      /**
       * 获取所有的客户
       */

      async queryCompanys() {
        this.listLoading = true;
        const responseData = await searchCompanysByKey("", this.pager);
        // const responseData = await searchCompanysByKey();
        if (responseData && responseData.errorCode === 0 && responseData.data) {
          this.companyInfo = responseData.data.content[0] || {};
          let temp = this.companyInfo.imageUrl.split(",");
          temp.forEach((item) => {
            this.imageList.push({ fileImage: item, serverImage: item });
          });
          // console.log("aaa");
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       * 触发文件选择
       */
      triggerSelectFile() {
        document.querySelector("#orderImageFile").click();
      },
      triggerSelectQrCode() {
        document.querySelector("#orderQrcodeFile").click();
      },

      /**
       * 选中
       */
      selectedImageFile($event, tag) {
        let files = $event.target.files;
        let file = null;
        if (files && files.length) {
          file = files[0];
        }

        if (file && /^image\/\w+/.test(file.type)) {
          if (file.size / 1024 / 1024 / 10 > 1) {
            //10M
            Notification.error({
              title: "错误",
              message: "请选择一个小于10M图片",
            });
            return;
          }
        } else {
          Notification.error({
            title: "错误",
            message: "请选择一个图片文件",
          });
          return;
        }
        //获取 URL.createObjectURL 地址
        const fileImageUrl = getFileUrl("#orderImageFile");
        let image = new Image();
        image.src = fileImageUrl;
        this.loading = true;
        image.onload = () => {
          this.imageList.push({ fileImage: fileImageUrl, serverImage: "" });
          this.uploadImageFile(file);
        };
        image.onerror = () => {
          Notification.error({
            title: "错误",
            message: "请选择一个正确的图片文件",
          });
        };
      },
      /**
       * @param index 在imageList中的下标
       */
      delImage(index) {
        this.imageList.splice(index, 1);
      },

      /**
       * 上传图片
       * @param imageFile Blob file
       */
      async uploadImageFile(imageFile) {
        const aafile = new File([imageFile], "cnm.png");
        const uploadData = await uploadImageFile(aafile);
        if (
          uploadData &&
          uploadData.errorCode === 0 &&
          uploadData.data &&
          uploadData.data.result
        ) {
          this.imageList[this.imageList.length - 1].serverImage =
            uploadData.data.fpicurl;
          document.getElementById("headImgfileForm").reset();
          this.loading = false;
        }
      },
      /**
       * 权限判断
       */
      checkPermission() {
        // let roleId = this.user.roleId;
        // if (roleId !== 1) {
        //   this.disabled = true;
        //   this.companyInfo.proxyCustomer = this.brandList[0].id;
        // }
        const userRole = this.user.roleCode;
        if (!/Admin/.test(userRole)) {
          this.disabled = true;
          // if (this.brandList[0].id)

          this.companyInfo.proxyCustomer = this.user.customerName;
          // console.log("2: "+this.brandList)
        }
      },
      /**
       * 获取所有的品牌商
       */
      async queryCustomers(key) {
        const responseData = await queryBranderExist();
        if (responseData.errorCode === 0) {
          this.brandList = (responseData && responseData.data) || [];
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       * [更新客户信息]
       * @return {[type]} [description]
       */
      async updateCompanyInfo() {
        let companyInfoData = null;
        const info = Object.assign({}, this.companyInfo);
        info.imageUrl = this.imgUrlList.join(",");
        if (this.isEdit) {
          companyInfoData = await updateCompanyInfo(info);
        } else {
          delete info.id;
          companyInfoData = await createCompany(info);
        }
        if (
          companyInfoData &&
          companyInfoData.errorCode === 0 &&
          companyInfoData.data
        ) {
          this.$emit("onSubmit", companyInfoData.data);
          Notification.success({
            title: "成功",
            message: this.isEdit ? "修改介绍页面成功" : "添加公司介绍页面成功",
          });
        } else {
          Notification.error({
            title: "失败",
            message: companyInfoData.data.errorMessage,
          });
        }
        return false;
      },

      /**
       * [提交表单]
       * @return {[type]} [description]
       */
      onSubmit() {
        this.$refs.pubNumberForm.validate((valid) => {
          if (valid) {
            this.imgUrlList = [];
            this.imageList.forEach((item) => {
              this.imgUrlList.push(item.serverImage);
            });
            this.updateCompanyInfo();
          }
        });

        // this.$refs.pubNumberForm.validate((valid) => {
        //   if (valid) {
        //     this.updateCompanyInfo();
        //       alert(1)
        //   }
        // });
      },
    },

    async created() {
      this.companyInfo = Object.assign({}, this.propcompanyInfo);
      if (this.user.role.indexOf("brands") > -1 && this.user.customerId) {
        this.companyInfo.customer = this.user.customerId;
        this.isBrand = true;
      }
      this.comName = this.companyInfo.customerName;
      this.queryCustomers();
      if (this.isEdit) {
        let temp = this.companyInfo.imageUrl.split(",");
        temp.forEach((item) => {
          this.imageList.push({ fileImage: item, serverImage: item });
        });
      }
      this.queryCompanys();
      //提前初始化选项，让其选项在focus的时候就获取到
    },
    watch: {
      customerDetailVisible(newVal, oldVal) {
        if (newVal === false) {
          this.isEdit = false;
          this.companyInfo = {
            id: "",
            name: "",
            customer: "",
            imageUrl: "",
            customerName: "",
            remarks: "",
          };
          this.imageList = [];
          if (this.isBrand) {
            this.companyInfo.customer = this.user.customerId;
          }
          this.$refs.pubNumberForm.resetFields();
        } else {
          if (!this.isEdit) {
            this.queryCustomers();
          }
        }
      },
      propcompanyInfo(newVal) {
        if (newVal && this.isEdit) {
          this.companyInfo = Object.assign({}, newVal);
          this.comName = this.companyInfo.customerName;
          let temp = this.companyInfo.imageUrl.split(",");
          console.log(temp);
          temp.forEach((item) => {
            this.imageList.push({ fileImage: item, serverImage: item });
          });
        }
      },
    },
  };
</script>
